
@use '@ruf/theming' as ruf;

@mixin chex-dialog-attachment-theme($theme) {
    $error: map-get($theme, error);
    
    .chex-attch-doc-error{
        color: ruf.ruf-color($error, 600);
    }

    .mandatoryFieldsWarning{
      color: ruf.ruf-color($error, 600) !important;
    }
  }

@mixin chex-dialog-attachment-typography($config) {

    .chex-attch-doc-info-text{
        font: normal normal normal ruf.ruf-to-rem(11px)/ruf.ruf-to-rem(15px) Source Sans Pro;
        margin-top: ruf.ruf-to-rem(10px);
        letter-spacing: ruf.ruf-to-rem(0px);
        overflow: hidden; 
    }
  }