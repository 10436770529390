//@use '../../../../themes/chexsystem-ui-theme.scss';
/* You can add global styles to this file, and also import other style files */
//@import '../../../../themes/chexsystem-ui-theme.scss';
@import './chex-system-ui-theme';
/*Page header should remain fixed hence subtracting header's height*/
.app-section-container {
  height: calc(100% - 60px);
  overflow-y: auto;
  padding-bottom: 30px;
}

.user-container {
  height: calc(100% - 93px);
  overflow-y: auto;
  padding-bottom: 30px;
}

.grecaptcha-badge {
  visibility: hidden;
}
//for card alignment and border-radius

.card_alignment {
  padding-top: 30px !important;
  margin-left: 3% !important;
  border-radius: 12px !important;
  margin-right: 3% !important;
}
.ruf-card.fis-override .ruf-card-panel.mat-card {
  border-radius: 12px !important;
}
// only show recaptcha badge on needed pages
body.recaptcha {
  .grecaptcha-badge {
    visibility: visible;
  }
}
//page header css//
.ruf-page-header.fis-override
  .ruf-pageheader-toolbar
  .ruf-toolbar-rows
  .ruf-toolbar-first-row
  > *:first-child:not(.ruf-square) {
  margin-left: 7% !important;
}

.ruf-stepper .ruf-stepper-header .ruf-header-inner-container {
  padding: 1.0625rem 0 !important;
  margin-left: 7% !important;
}
.ruf-page-header
  .ruf-pageheader-toolbar
  > .ruf-toolbar-rows
  .ruf-page-header-title,
.ruf-page-header
  .ruf-pageheader-toolbar
  > .ruf-toolbar-rows
  .ruf-page-header-metric {
  font-size: 18px !important;
}
//for body text alignment
.text {
  margin-left: 3% !important;
  margin-right: 3% !important;
}

//for subheading FIS black
.mat-subheading-2 {
  color: #012834;
}
.mat-body-2 {
  color: #012834;
}
//for mainheading FIS black
.header_text {
  color: #012834;
}

.chx-datagrid-cell {
  text-align: center !important;
}



.ruf-dynamic-menubar .mat-tab-group.mat-primary .mat-tab-list .mat-ink-bar {
  background-color: ruf-color($primary);
}

.ruf-dynamic-menubar
  .mat-tab-group
  .mat-tab-header-pagination:not(.mat-tab-header-pagination-disabled).mat-elevation-z4.mat-tab-header-pagination-after,
.ruf-dynamic-menubar
  .mat-tab-group
  .mat-tab-header-pagination:not(.mat-tab-header-pagination-disabled).mat-elevation-z4.mat-tab-header-pagination-before {
  border-color: rgba(var(--ruf-foreground-border, 223, 223, 223), 1);
  box-shadow: none;
  color: inherit;
}

.ruf-dynamic-menubar .mat-tab-group .mat-tab-header-pagination-chevron {
  border-color: rgba(var(--ruf-foreground-text, 1, 40, 52), 1);
}

#loading-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}
.cdk-overlay-pane mat-dialog-container.mat-dialog-container .ruf-dialog-panel.fis-override.custom_100vh .ruf-dialog-container .ruf-dialog-content{
  max-height: 90vh !important;
}

.mat-dialog-container {
  overflow-y: hidden  !important;
}

.ruf-dialog-panel.custom-hide-close-icon .ruf-dialog-header-bar .mat-icon-button{
display: none  !important;
}

// .ruf-dialog-panel:not(.ruf-dialog-rtl) .ruf-dialog-header-bar .mat-icon-button {
//   visibility: hidden !important;
// }

//space between footer and end of white area
