@import 'node_modules/@ruf/theming/styles/theming/theming';
@import 'node_modules/@ruf/shell/src/card/card.theme';

// a convenience mixin that aggregates all of your component theme mixins
@mixin chx-root-app--theme($theme) {
  @include ruf-card-theme($theme);
}

@mixin chx-root-app-typography($config) {
  chx-root-app-card {
  }
}
#card_panel_0 {
  border-radius: 12px !important;
}

.mat-tooltip {
  margin: 2px !important;
  // max-width: 280px !important;
}
.mat-tooltip-panel .mat-tooltip.fis-style
{
  text-transform: none !important;
}

.mat-form-field.mat-focused .mat-form-field-label
{
    color: none !important;
}

::ng-deep .mat-form-field-underline {
  display: none;
}

// ::ng-deep .mat-checkbox-background
// {
//   background: none!important;
//   border: solid 1px!important;
// }

::ng-deep .mat-checkbox[fisStyle].mat-primary.mat-checkbox-checked 
.mat-checkbox-inner-container .mat-ripple-element, 
.mat-checkbox[fisStyle].mat-primary.mat-checkbox-indeterminate 
.mat-checkbox-inner-container .mat-ripple-element
{
  background: none !important;
}

::ng-deep .mat-checkbox[fisStyle]:not(.mat-checkbox-checked):not(.mat-checkbox-disabled):not(.mat-checkbox-indeterminate).mat-primary .mat-ripple-element, .mat-checkbox[fisStyle]:not(.mat-checkbox-checked):not(.mat-checkbox-disabled):not(.mat-checkbox-indeterminate).mat-accent .mat-ripple-element, .mat-checkbox[fisStyle]:not(.mat-checkbox-checked):not(.mat-checkbox-disabled):not(.mat-checkbox-indeterminate).mat-warn .mat-ripple-element
{
  background: none !important;
}
::ng-deep .mat-radio-button[fisStyle]:not(.mat-radio-disabled):not(.mat-radio-checked) .mat-radio-outer-circle
{
  background: none !important;
}
.chex-footer_fix{
  min-height:86.5vh;
}