@use '@ruf/theming' as ruf;

@mixin chex-footer-theme($theme) {
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $info: map-get($theme, info);
  $background: map-get($theme, background );

  .footerContainer {

    &.enh-desktop-footer {
      .desktop-footer-section {
        display: block;
      }

      .mobile-footer-section {
        display: none;
      }
    }

    &.enh-mobile-footer {
      .desktop-footer-section {
        display: none;
      }

      .mobile-footer-section {
        display: block;
      }
    }

    .copyright-text {
      color: ruf.ruf-color($background,footer);
    }

    

    // .footer-container{
    //   background: ruf.ruf-color($background,contrast);
    // }

    // .footer-container-copyright {
    //   background: ruf.ruf-color($background,contrast);
    // }

    .lower-link {
      color:ruf.ruf-color($background,footer);    
    }
   
  }

  .footerContainer .footer-container, .footerContainer .footer-container-copyright {

    background: var(--ruf-background-contrast, #000c10);
   
   }
}