@use '@ruf/theming' as ruf;

@mixin chex-registration-theme($theme) {

}

@mixin chex-registration-typography($config) {

  .chex-font{
    font-weight: ruf.ruf-font-weight($config, bold);
  }
}