@use '@ruf/theming' as ruf;

@mixin chex-dialog-dispute-theme($theme) {


}

@mixin chex-dialog-dispute-typography($config) {

  .chex-dialog-dispute-pi-caption{
    font-weight: ruf.ruf-font-weight($config, bold);
  }
  
}