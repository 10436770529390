@use '@ruf/theming' as ruf;

@mixin chex-profile-theme($theme) {

  .mat-form-field [disabled], .mat-select-disabled .mat-select-value
  {
    color: ruf.ruf-color($disabled) !important;
    -webkit-text-fill-color: ruf.ruf-color($disabled) !important;
    opacity: 1; /* required on iOS */
  }
}

@mixin chex-profile-typography($config) {

  .chex-profile-caption{
    font-weight: ruf.ruf-font-weight($config, bold);
  }
  
}