//@use '@ruf/prebuilt-themes/fis-default';

@mixin chex-dashboard-landing-theme($theme) {
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
  
    .chx-carouselfont {
      color: ruf.ruf-color($foreground, lighter-text);
    }

    .ruf-carousel-indicator-selected{
        background: ruf.ruf-color($primary);
      }
  }

   
.btn-primary-blue-on-dark {
  background: #1279aa;
  padding: 8px 30px;
  border: 2px solid #1279aa;
  border-radius: 67rem;
  color: #fff;
  font-family: "source_sans_probold",sans-serif;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  transition: .3s all;
  -webkit-transition: .3s all;
  font-weight: normal
}
  
.btn-primary-blue-on-dark:hover {
  background: none;
  border: 2px solid #1279aa;
  border-radius: 67rem;
  color: #1279aa
}
 
.btn-primary-blue-on-dark:active {
  background: #fff;
  outline: none;
  color: #012834
}
 
.img-style{
  background-size: cover;
  background-position: center center ;
}

.content-style {
  // font-weight: normal;
  padding-bottom: 35px;
}

