@mixin chex-dashboard-corres-theme($theme) {
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $info: map-get($theme, info);
    $background: map-get($theme, background);
    
    .chex-db_correspondance_container{
          background: ruf.ruf-color($background, card) 0% 0% no-repeat padding-box;
          border: ruf.ruf-to-rem(1px) solid ruf.ruf-color($foreground, border);
      }

  }