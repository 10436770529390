
//@import '~@ruf/prebuilt-themes/fis-default';
 @mixin chex-dashboard-dispute-theme($theme) {
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $info: map-get($theme, info);
    $emphasis: map-get($theme, emphasis);
    $success: map-get($theme, success);
    $background: map-get($theme, background);

    .chex-db_disputes_container{
         background: ruf.ruf-color($background, card) 0% 0% no-repeat padding-box;
         border: ruf.ruf-to-rem(1px) solid ruf.ruf-color($foreground, border);
     }
    .chex-db_disputes_grid_item2_shape_inp{
        background: ruf.ruf-color($emphasis, 500) 0% 0% no-repeat padding-box;
    }
    .chex-db_disputes_grid_item2_shape_sub{
         background: ruf.ruf-color($info, 500) 0% 0% no-repeat padding-box;
     }
      .chex-db_disputes_grid_item2_shape_app{
          background: ruf.ruf-color($success, 500) 0% 0% no-repeat padding-box;
      }



  }