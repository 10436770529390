@mixin chex-top-header-theme($theme) {
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    $info: map-get($theme, info);
    $background: map-get($theme, background);


    .headerContainer {

        &.enh-desktop-header {
            .menu_section {
                display: block;
            }
            .sidemenu_section {
                display: none;
            }
        }

        &.enh-mobile-header {
            .menu_section {
                display: none;
            }
            .sidemenu_section {
                display: block;
            }
        }
    }

    .sidemenu-header {
        background: ruf.ruf-color($background, app-bar);
        border-bottom-color: ruf.ruf-color($foreground, border);
    }
    .mat-sidenav
    {
        background: ruf.ruf-color($background, app-bar);
        border-bottom-color: ruf.ruf-color($foreground, border);
    }
}