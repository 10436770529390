@use '@ruf/theming' as ruf;

@mixin chex-phonecontrol-theme($theme) {
  $error: map-get($theme, error);
  $foreground: map-get($theme, foreground);

  .chex-phoneType-dropdown{
    border-color: ruf.ruf-color($foreground, border) !important;
  }

  .chex-area {
    border-color: ruf.ruf-color($foreground, border) !important;
  }

  .chex-exchange {
    border-color: ruf.ruf-color($foreground, border) !important;
  }

  .chex-subscriber {
    border-color: ruf.ruf-color($foreground, border) !important;
  }

  .chex-area-error {
    border-color: ruf.ruf-color($error, 600) !important;
  }

  .chex-exchange-error {
    border-color: ruf.ruf-color($error, 600) !important;
  }

  .chex-subscriber-error {
    border-color: ruf.ruf-color($error, 600) !important;
  }

}