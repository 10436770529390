@use '@ruf/theming' as ruf;


@mixin chx-contact-us-theme($theme) {
  $foreground: map-get($theme, foreground);
  $primary: map-get($theme, primary);
  $info: map-get($theme, info);
  $emphasis: map-get($theme, emphasis);
  $success: map-get($theme, success);

  .icon_phone {
    color: ruf.ruf-color($primary);
  }

  .icon_mail {
    color: ruf.ruf-color($primary);
  }

}

@mixin chex-contact-us-typography($config) {

  .icon_phone {
    display: flex;
    justify-content: space-evenly;
    font-size: xx-large;
  }

  .icon_mail {
    display: flex;
    justify-content: space-evenly;
    font-size: xx-large;
  }

}