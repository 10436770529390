@use '@ruf/theming' as ruf;

@mixin chex-consumer-disclosure-dispute-theme($theme) {


}

@mixin chex-consumer-disclosure-dispute-typography($config) {

  .chex-dispute-pi-caption{
    font-weight: ruf.ruf-font-weight($config, bold);
  }
  .mat-table[fisStyle] .mat-row .mat-cell{
    font-size: ruf.ruf-font-size($config, caption);
  }
}