@use '@ruf/theming' as ruf;
// a convenience mixin that aggregates all of your component theme mixins
@mixin chex-common-theme($theme) {
  $foreground: map-get($theme, foreground);

  html,
  body {
    color: ruf.ruf-color($foreground, text);
  }
}

@mixin chex-common-structural($theme, $config) {
  //for content of page alignment from left and right
  .chex-page-left-right-alignment {
    margin-left: 7%;
    margin-right: 7%;
  }
  //for scrollbar removal from dialog boxes
  .cdk-overlay-pane.custom-dialog {
    .mat-dialog-content {
      // background-color: red;
      // min-height: 30px;
      overflow-y: hidden;
    }
  }

  //for scrollbar removal along with close icon hide
  .cdk-overlay-pane.custom-dialog-without-close {
    .mat-dialog-content {
      // background-color: red;
      // min-height: 30px;
      overflow-y: hidden;
    }
    .ruf-dialog-panel:not(.ruf-dialog-rtl)
      .ruf-dialog-header-bar
      .mat-icon-button {
      visibility: hidden !important;
    }    
  }
  
  .cdk-overlay-pane.fis-style mat-dialog-container.mat-dialog-container .mat-dialog-title{
    text-transform: none !important;
  }

  //for scrollbar removal along with close icon hide
  .cdk-overlay-pane.custom-dialog-without-close-scroll {
    .ruf-dialog-panel:not(.ruf-dialog-rtl)
      .ruf-dialog-header-bar
      .mat-icon-button {
      visibility: hidden !important;
    }
  }
  //extra space from bottom, rufmarginbottom provides only 10px.
  .chex-bottom_space {
    margin-bottom: 30px;
  }

  .chex-hand-cursor {
    cursor: pointer;
  }
  .chex-top_label_link {
    margin-top: ruf.ruf-to-rem(30px);
  }

  .chex-no-record-message {
    margin-top: 0px;
  }

  .chex-common-dispute-snackbar {
    max-width: ruf.ruf-to-rem(646px) !important;

  }
}

@mixin chex-common-typography($config) {
  .ruf-page-header
    .ruf-pageheader-toolbar
    > .ruf-toolbar-rows
    .ruf-page-header-title {
    text-transform: none !important;
  }

  // ruf-dynamic-menubar
  .ruf-labeled-icon > span {
    text-transform: none !important;
  }

  //ruf-dynamic-data-table
  .mat-table[fisStyle] .mat-header-row * {
    text-transform: none;
  }
}
