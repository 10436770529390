@mixin chex-quiz-timer-theme($theme) {
  $background: map-get($theme, background); 

  .chex-timer_rect {
    background: ruf.ruf-color($background, app-canvas) 0% 0% no-repeat padding-box;
  }

  .chex-timer_minute_rect {
    background: ruf.ruf-color($background, base) 0% 0% no-repeat padding-box;
  }
  .chex-timer_seconds_rect {
    background: ruf.ruf-color($background, base) 0% 0% no-repeat padding-box;
  }

}


@mixin chex-quiz-timer-typography($config) {

  .chex-timer_upperText {
    font: normal normal 600 ruf.ruf-to-rem(14px)/ruf.ruf-to-rem(18px) Source Sans Pro;
  }
  .chex-timer_minute_text {
    
    font: normal normal 600 ruf.ruf-to-rem(31px)/ruf.ruf-to-rem(40px) Source Sans Pro;
    
  }
  .chex-timer_minute_grey_text {
    
    font: normal normal 300 ruf.ruf-to-rem(13px)/ruf.ruf-to-rem(17px) Source Sans Pro;
    
  }
  .chex-timer_seconds_text {
    
    font: normal normal 600 ruf.ruf-to-rem(31px)/ruf.ruf-to-rem(40px) Source Sans Pro;
    
  }
  .chex-timer_seconds_grey_text {
    
    font: normal normal 300 ruf.ruf-to-rem(13px)/ruf.ruf-to-rem(17px) Source Sans Pro;
    
  }
}
